import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { VehicleWidget } from 'Components'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/utilimaster/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/utilimaster/Templates/fitmentSearch/singleVehicleGarage.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchHeader,
SearchResult,
SearchBox,
SearchBoxDialogButton,
VehicleWidget,
Garage,
SingleVehicleGarage,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};