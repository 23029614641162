//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-660:_7704,_512,_7952,_936,_6980,_3688,_3920,_8336;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-660')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-660', "_7704,_512,_7952,_936,_6980,_3688,_3920,_8336");
        }
      }catch (ex) {
        console.error(ex);
      }