
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('h3', { 'className': 'cm_single-vehicle-garage_container' }, this.template === 'active' ? [
        ' ',
        window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle',
        ' '
    ] : null, this.template === 'locked' ? [
        _createElement('span', {
            'className': 'cm_single-vehicle-garage_model',
            'key': '2091'
        }, this.vehicle.Vehicle),
        _createElement('span', {
            'className': 'cm_single-vehicle-garage_clear',
            'data-cm-role': 'discard-vehicle',
            'key': '2093'
        }, 'Clear')
    ] : null);
}
        export const componentNames = []